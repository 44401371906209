import React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import Backdrop from '../../../UI/Backdrop/Backdrop'

import logo from '../../../../images/ocOval.png'
import styles from './SideDrawer.module.css'

const SideDrawer = (props) => {
  return (
    <>
      <Backdrop clicked={props.close} show={props.opened} />
      <nav className={styles.mobile_nav + ' ' + (props.opened ? styles.open : styles.close)} >
        <Link to="/"><img src={logo} className={styles.logo} alt="Occupational Consulting LLC logo. Redirects to Home." /></Link>
        <button className={styles.close_btn} onClick={props.close}><FontAwesomeIcon icon={faTimes} /><span className="visually-hidden">Close Navigation</span></button>
        <ul>
          <li><Link to="/about/">About</Link></li>
          <li><Link to="/services/">Services</Link></li>
          <li><Link to="/referral/">Referral</Link></li>
          <li><Link to="/contact/">Contact</Link></li>
        </ul>
      </nav>
    </>
  )
}

export default SideDrawer