import React, { useState } from 'react'
import { Link } from 'gatsby'

import NavItems from './NavItems/NavItems'
import SideDrawer from './SideDrawer/SideDrawer'

import logo from '../../../images/ocOval.png'
import styles from './Navbar.module.css'

const Navbar = (props) => {
  const [menuOpened, setMenuOpened] = useState(false)

  return (
    <header className={styles.navbar}>
      <Link to="/"><img src={logo} className={styles.logo} alt="Occupational Consulting LLC logo. Redirects to Home." /> Occupational Consulting, LLC</Link>
      <NavItems />
      <SideDrawer opened={menuOpened} close={() => setMenuOpened(!setMenuOpened)} />
      <button className={styles.menu_toggle} onClick={() => setMenuOpened(!menuOpened)}>MENU</button>
    </header>
  )
}

export default Navbar