import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faFax, faMapMarkerAlt, faPhone } from '@fortawesome/free-solid-svg-icons'

import { formatPhoneNumber } from '../../../utilities/formatPhoneNumber'

import styles from './Footer.module.css'

const Footer = () => {
  const footerData = useStaticQuery(graphql`
  query FooterQuery {
    markdownRemark(frontmatter: {title: {eq: "Contact"}}) {
      frontmatter {
        streetAddress
        streetAddressLine2
        city
        state
        zip
        officePhone
        cellPhone
        fax
        email
      }
    }
  }
  `)
  const data = footerData.markdownRemark.frontmatter
  return (
    <footer className={styles.footer}>
      <div className="flex-row">
        <div>
          <h3>Contact Information</h3>
          <address>
            <FontAwesomeIcon icon={faMapMarkerAlt} />
            <span>{data.streetAddress}<br />
              {data.streetAddressLine2 ? (
                <>
                  {data.streetAddressLine2} <br />
                </>
              ) : null}
              {data.city}, {data.state} {data.zip}</span>
          </address>
          <p><FontAwesomeIcon icon={faPhone} /> Office: <a href={`tel:+1${data.officePhone}`}>{formatPhoneNumber(data.officePhone)}</a></p>
          <p><FontAwesomeIcon icon={faPhone} /> Cell: <a href={`tel:+1${data.cellPhone}`}>{formatPhoneNumber(data.cellPhone)}</a></p>
          <p><FontAwesomeIcon icon={faFax} /> Fax: <a href={`tel:+1${data.fax}`}>{formatPhoneNumber(data.fax)}</a></p>
          <p><FontAwesomeIcon icon={faEnvelope} /> <a href={`mailto:${data.email}`}>{data.email}</a></p>
        </div>
        <iframe width="300" height="200" frameBorder="0" style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJw6GV30wrKIYRoDVjfJZZTFY&key=AIzaSyBsCXjfYzPHSoytwTNb5Sgy0BbLf1XguSg" allowFullScreen></iframe>
      </div>
      <div className={styles.fine_print}>
        <a href="https://www.facebook.com/OccupationalConsulting/" className="social_link" target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faFacebookSquare} /> <span className="visually-hidden">Occupational Consulting, LLC</span>
        </a>
        <small>&copy; {new Date().getFullYear()} Occupational Consulting, LLC</small>
      </div>
    </footer>
  )
}

export default Footer