import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Navbar from './Navbar/Navbar'

import styles from './Layout.module.css'
import '../../styles/styles.css'
import Footer from './Footer/Footer'

import logo from '../../images/ocLogo.png'

const Layout = (props) => {
  const data = useStaticQuery(graphql`
  query LayoutQuery {
    markdownRemark(frontmatter: {title: {eq: "Home"}}) {
      frontmatter {
        headerImage {
          photo {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          altText
        }
      }
    }
  }
  `)
  return (
    <div className={styles.layout}>
      <Helmet defer={false}>
        <title>{props.title} | Occupational Consulting, LLC</title>
        <meta name="description" content="Offering excellence in vocational rehabilitation." />
      </Helmet>
      <a href="#main" className="visually-hidden">Skip to Main Content</a>
      <Navbar />
      <div className={styles.header}>
        <Img fluid={data.markdownRemark.frontmatter.headerImage.photo.childImageSharp.fluid} alt={data.markdownRemark.frontmatter.headerImage.altText} imgStyle={{ objectPosition: 'top center' }} />
        <div className={styles.overlay}>
          <img src={logo} alt="Occupational Consulting Logo" />
        </div>
      </div>
      <main id="main">{props.children}</main>
      <Footer />
    </div>
  )
}

export default Layout