import React from 'react'

import NavItem from './NavItem/NavItem'

import styles from './NavItems.module.css'

const NavItems = (props) => {
  return (
    <nav>
      <ul className={styles.desktop_nav}>
        <NavItem link="/about/">About</NavItem>
        <NavItem link="/services/">Services</NavItem>
        <NavItem link="/referral/">Referral</NavItem>
        <NavItem link="/contact/">Contact</NavItem>
      </ul>
    </nav>
  )
}

export default NavItems